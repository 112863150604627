import $ from "jquery";
import "slick-carousel";

function initImageSlider() {

  if ($(".js-image-slider").length) {
    $(".js-image-slider").each(function () {
      const $slider = $(this);
      const isSponsorCarousel = $slider.hasClass("sponsor-carousel__slider");
      $slider.slick({
        arrows: true,
        dots: false,
        autoplay: false,
        slidesToShow: 2,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        easing: "linear",
        responsive: isSponsorCarousel ? [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              vertical: true,
              verticalSwiping: true,
              centerPadding: '40px;',
              centerMode: true,
            }
          }
        ] : []
      });
    });
  }

}

$(document).ready(initImageSlider);
