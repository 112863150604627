import $ from "jquery";

$(document).ready(function () {
    function setEqualHeight() {
        var maxHeight = 0;

        // Reset height to auto to avoid stacking previous calculations
        $('.archive-card').css('height', 'auto');

        // Find the maximum height
        $('.archive-card').each(function () {
            var thisHeight = $(this).outerHeight();
            if (thisHeight > maxHeight) {
                maxHeight = thisHeight;
            }
        });

        // Set all elements to the maximum height
        $('.archive-card').height(maxHeight);
    }

    // Run on page load
    setEqualHeight();

    // Run on window resize to adjust heights dynamically
    $(window).resize(function () {
        setEqualHeight();
    });
});
