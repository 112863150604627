import $ from "jquery";

$(document).ready(initMegaMenu);

function initMegaMenu() {
  if ($(".js-mega-menu").length) {
    $(window).on("resize", resetMenuStates);
    
    $(".js-mega-menu").each(function () {
      const menuNode = this;
      const menu_id = $(menuNode).data("menuId");

      setupDesktopMenuEvents(menuNode, menu_id);
      setupMobileMenuEvents(menuNode, menu_id);
    });

    setupGlobalEvents();
  }
}

function resetMenuStates() {
  $(".js-mega-menu").removeClass("mega-is-active mega-menu--mobile");
  $(".header__mega-menu").removeClass("mega-is-active");
  $(".header").removeClass("mega-is-active mega-menu-is-active");
  $(".menu-item").removeClass("menu-item--active");
  $(".dropdown-toggle-active").removeClass("dropdown-toggle-active"); 
}

function setupDesktopMenuEvents(menuNode, menu_id) {
  $(document).on("mouseenter mouseover", `.nav--main #${menu_id}-desktop`, function (event) {
    const itemIsActive = $(".menu-item--active");
    if (itemIsActive.length > 0) {
      itemIsActive.removeClass("menu-item--active");
      $(".dropdown-toggle-active").removeClass("dropdown-toggle-active");
    }
    $(".header").addClass("mega-is-active mega-menu-is-active");
    $(".header__mega-menu").addClass("mega-is-active");
    $(".js-mega-menu").removeClass("mega-is-active");
    $(menuNode).addClass("mega-is-active");
    $(this).addClass("menu-item--active");
    $(this).find(".dropdown-toggle").addClass("dropdown-toggle-active");
  });

  $(menuNode).on("mouseenter", function () {
    $(`.nav--main #${menu_id}-desktop`).addClass("menu-item--active");
  });

  $(menuNode).on("mouseleave", function () {
    $(`.nav--main #${menu_id}-desktop`).removeClass("menu-item--active");
  });

  $(document).on("keydown", `.nav--main #${menu_id}-desktop`, function (event) {
    handleKeydownEvent(event, menuNode);
  });

  $(document).on("keydown", ".js-mega-menu .grid__column a", handleTabNavigation);
}

function setupMobileMenuEvents(menuNode, menu_id) {
  $(document).on("click", `.js-mobile-nav.is-active .menu-item--mega-menu #${menu_id} ~ .dropdown-toggle`, function (e) {
    e.preventDefault();
    const $dropdownToggle = $(this);
    const $parentLi = $dropdownToggle.closest("li");
    const submenu = $(this).find("~ .sub-menu-container");
    $(".header__mega-menu").addClass("mega-is-active header__mega-menu--mobile");
    $(".js-mega-menu").removeClass("mega-is-active");
    $(submenu).toggleClass("sub-menu-container--active");
    $($parentLi).toggleClass("menu-item--active");
  });
}

function setupGlobalEvents() {
  $(document).on("mouseleave", ".js-mega-menu, .menu-item-has-children:not(.nav__list--mobile .menu-item-has-children)", function(e){
    if (!$(e.relatedTarget).closest('.js-mega-menu, .menu-item-has-children').length) {
      resetMenuStates();
    }
  });

  document.body.addEventListener("keydown", function (e) {
    if (e.key === "Escape") {
      resetMenuStates();
    }
  });

  $(document).on("mouseenter", ".header__top-bar", resetMenuStates);
}

function handleKeydownEvent(event, menuNode) {
  if (event.type === "keydown" && event.key === "Enter") {
    event.preventDefault();
  }
  if (event.key === "Tab") return;
  
  $(".header").addClass("mega-is-active mega-menu-is-active");
  $(".header__mega-menu").addClass("mega-is-active");
  $(".js-mega-menu").removeClass("mega-is-active");
  $(menuNode).addClass("mega-is-active");
  $(this).addClass("menu-item--active");

  // Focus the current submenu item
  const currentMenuItem = $(menuNode).find("ul li.current-menu-item");
  if (currentMenuItem.length) {
    currentMenuItem.find("a").first().trigger("focus");
  } else {
    $(menuNode).find("ul li a").first().trigger("focus");
  }
}

function handleTabNavigation(event) {
  if (event.key !== "Tab") return;
  
  const nextMenuId = $(this).closest(".js-mega-menu").next().data("menuId");
  const $lastGridGroup = $(this).closest(".grid").parent().find(".grid").last().find(".grid__column").last();
  const $lastLink = $lastGridGroup.is("UL")
    ? $lastGridGroup.find("li").last().find("a")
    : $lastGridGroup.find(".menu-post-item a");

  // Return to next main nav if last item in submenu
  if (this === $lastLink[0]) {
    $(`.nav--main #${nextMenuId}-desktop`).trigger("keydown");
  }
}
